"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogPosition = exports.BasePosition = void 0;
var BasePosition;
(function (BasePosition) {
    BasePosition["START"] = "Start";
    BasePosition["CENTER"] = "Center";
    BasePosition["END"] = "End";
    BasePosition["STRETCH"] = "Stretch";
})(BasePosition = exports.BasePosition || (exports.BasePosition = {}));
var DialogPosition;
(function (DialogPosition) {
    DialogPosition["LEFT"] = "left";
    DialogPosition["LEFT_START"] = "left-start";
    DialogPosition["LEFT_END"] = "left-end";
    DialogPosition["RIGHT"] = "right";
    DialogPosition["RIGHT_START"] = "right-start";
    DialogPosition["RIGHT_END"] = "right-end";
    DialogPosition["TOP"] = "top";
    DialogPosition["TOP_START"] = "top-start";
    DialogPosition["TOP_END"] = "top-end";
    DialogPosition["BOTTOM"] = "bottom";
    DialogPosition["BOTTOM_START"] = "bottom-start";
    DialogPosition["BOTTOM_END"] = "bottom-end";
})(DialogPosition = exports.DialogPosition || (exports.DialogPosition = {}));
