"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const lodash_es_1 = require("lodash-es");
// remove empty values
function removeEmpty(obj) {
    const newObj = Object.assign({}, obj);
    for (const k in newObj) {
        if (newObj[k] === undefined) {
            delete newObj[k];
        }
    }
    return newObj;
}
function useStyle(currentStyle, additionalProps) {
    const additionalPropsRef = (0, react_1.useRef)(additionalProps);
    const currentStyleRef = (0, react_1.useRef)(currentStyle);
    // using deep equal in order to allow sending new object for additionalProps
    // but with the same values inside i.e. '{ color, width }'
    if (!(0, lodash_es_1.isEqual)(additionalPropsRef.current, additionalProps)) {
        additionalPropsRef.current = additionalProps;
    }
    if (!(0, lodash_es_1.isEqual)(currentStyleRef.current, currentStyle)) {
        currentStyleRef.current = currentStyle;
    }
    const currentStyleObj = currentStyleRef.current;
    const additionalPropsObj = additionalPropsRef.current;
    return (0, react_1.useMemo)(() => {
        const nonEmptyObj = removeEmpty(additionalPropsObj);
        if ((0, lodash_es_1.isEmpty)(nonEmptyObj))
            return currentStyleObj;
        return Object.assign(Object.assign({}, currentStyleObj), nonEmptyObj);
    }, [currentStyleObj, additionalPropsObj]);
}
exports.default = useStyle;
