"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isIE11 = exports.isFirefox = void 0;
function isFirefox() {
    return !!/Firefox\/([0-9.]+)(?:\s|$)/.exec(window.navigator.userAgent);
}
exports.isFirefox = isFirefox;
function isIE11() {
    return !!window.MSInputMethodContext && !!document.documentMode;
}
exports.isIE11 = isIE11;
