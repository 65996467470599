"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sizes = exports.BaseSizes = exports.SIZES = exports.BASE_SIZES_WITH_NONE = exports.BASE_SIZES = void 0;
exports.BASE_SIZES = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large"
};
exports.BASE_SIZES_WITH_NONE = Object.assign({ NONE: "none" }, exports.BASE_SIZES);
exports.SIZES = Object.assign({ XXS: "xxs", XS: "xs" }, exports.BASE_SIZES);
var BaseSizes;
(function (BaseSizes) {
    BaseSizes["SMALL"] = "small";
    BaseSizes["MEDIUM"] = "medium";
    BaseSizes["LARGE"] = "large";
})(BaseSizes = exports.BaseSizes || (exports.BaseSizes = {}));
var Sizes;
(function (Sizes) {
    Sizes["XXS"] = "xxs";
    Sizes["XS"] = "xs";
    Sizes["SMALL"] = "small";
    Sizes["MEDIUM"] = "medium";
    Sizes["LARGE"] = "large";
})(Sizes = exports.Sizes || (exports.Sizes = {}));
