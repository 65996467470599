"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NOOP = exports.convertToArray = exports.chainFunctions = exports.chainRefFunctions = void 0;
const lodash_es_1 = require("lodash-es");
function chainRefFunctions(funcsOrRefs, allowBreak = false) {
    return (args) => {
        for (let i = 0; i < funcsOrRefs.length; i++) {
            const funcOrRef = funcsOrRefs[i];
            try {
                let result;
                if ((0, lodash_es_1.isFunction)(funcOrRef)) {
                    result = funcOrRef(args);
                }
                else if (funcOrRef) {
                    funcOrRef.current = args;
                }
                if (result === false && allowBreak) {
                    return;
                }
            }
            catch (e) {
                console.error(e);
                return;
            }
        }
    };
}
exports.chainRefFunctions = chainRefFunctions;
function chainFunctions(funcs, allowBreak = false) {
    return (args) => {
        // eslint-disable-next-line no-restricted-syntax
        for (let i = 0; i < funcs.length; i++) {
            const func = funcs[i];
            try {
                const result = func && func(args);
                if (result === false && allowBreak) {
                    return;
                }
            }
            catch (e) {
                console.error(e);
                return;
            }
        }
    };
}
exports.chainFunctions = chainFunctions;
function convertToArray(input) {
    return (0, lodash_es_1.isArray)(input) ? input : [input];
}
exports.convertToArray = convertToArray;
function NOOP() { }
exports.NOOP = NOOP;
