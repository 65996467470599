"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStyle = void 0;
/**
 * Return style by key - used to fix noImplicitAny errors when referencing modular styles from ts files via index accessor
 * @param styles modular styles object
 * @param key string classname
 */
function getStyle(styles, key) {
    if (!key || !styles[key])
        return "";
    return styles[key];
}
exports.getStyle = getStyle;
