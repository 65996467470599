"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const lodash_es_1 = require("lodash-es");
function useResizeObserver({ ref, callback, debounceTime = 200 }) {
    const debouncedCallback = (0, react_1.useCallback)((0, lodash_es_1.debounce)(callback, debounceTime), [callback, debounceTime]);
    (0, react_1.useEffect)(() => {
        if (!window.ResizeObserver) {
            return;
        }
        if (!(ref === null || ref === void 0 ? void 0 : ref.current))
            return;
        function borderBoxSizeCallback(borderBoxSize) {
            const value = Array.isArray(borderBoxSize) ? borderBoxSize[0] : borderBoxSize;
            return window.requestAnimationFrame(() => {
                debouncedCallback({ borderBoxSize: value });
            });
        }
        let animationFrameId = null;
        const resizeObserver = new ResizeObserver(entries => {
            var _a;
            const entry = entries[0];
            if (entry && entry.borderBoxSize) {
                // handle chrome (entry.borderBoxSize[0])
                // handle ff (entry.borderBoxSize)
                if (!Array.isArray(entry.borderBoxSize)) {
                    animationFrameId = borderBoxSizeCallback(entry.borderBoxSize);
                }
                else {
                    const borderBoxEntry = entry.borderBoxSize[0];
                    animationFrameId = borderBoxSizeCallback(borderBoxEntry);
                }
            }
            else if (entry.contentRect) {
                // handle safari (entry.contentRect)
                const borderBoxSize = { blockSize: entry.contentRect.height, inlineSize: ((_a = entry === null || entry === void 0 ? void 0 : entry.contentRect) === null || _a === void 0 ? void 0 : _a.width) || 0 };
                animationFrameId = borderBoxSizeCallback(borderBoxSize);
            }
            else {
                return;
            }
        });
        resizeObserver.observe(ref === null || ref === void 0 ? void 0 : ref.current);
        return () => {
            if (debounceTime !== 0) {
                debouncedCallback.cancel();
            }
            if (animationFrameId) {
                window.cancelAnimationFrame(animationFrameId);
            }
            resizeObserver.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref === null || ref === void 0 ? void 0 : ref.current, callback, debounceTime, debouncedCallback]);
}
exports.default = useResizeObserver;
