"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HideShowEvent = void 0;
var HideShowEvent;
(function (HideShowEvent) {
    HideShowEvent["CLICK"] = "click";
    HideShowEvent["CLICK_OUTSIDE"] = "clickoutside";
    HideShowEvent["ESCAPE_KEY"] = "esckey";
    HideShowEvent["TAB_KEY"] = "tab";
    HideShowEvent["MOUSE_ENTER"] = "mouseenter";
    HideShowEvent["MOUSE_LEAVE"] = "mouseleave";
    HideShowEvent["ENTER"] = "enter";
    HideShowEvent["MOUSE_DOWN"] = "mousedown";
    HideShowEvent["FOCUS"] = "focus";
    HideShowEvent["BLUR"] = "blur";
    HideShowEvent["CONTENT_CLICK"] = "onContentClick";
})(HideShowEvent = exports.HideShowEvent || (exports.HideShowEvent = {}));
