"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonInputType = exports.BUTTON_ICON_SIZE = exports.BUTTON_KIND_CLASS_NAMES = exports.getActualSize = exports.OLD_BUTTON_SIZES = exports.ButtonColor = exports.ButtonType = void 0;
const sizes_1 = require("../../constants/sizes");
var ButtonType;
(function (ButtonType) {
    ButtonType["PRIMARY"] = "primary";
    ButtonType["SECONDARY"] = "secondary";
    ButtonType["TERTIARY"] = "tertiary";
})(ButtonType = exports.ButtonType || (exports.ButtonType = {}));
var ButtonColor;
(function (ButtonColor) {
    ButtonColor["PRIMARY"] = "primary";
    ButtonColor["POSITIVE"] = "positive";
    ButtonColor["NEGATIVE"] = "negative";
    ButtonColor["ON_PRIMARY_COLOR"] = "on-primary-color";
    ButtonColor["ON_INVERTED_BACKGROUND"] = "on-inverted-background";
    ButtonColor["BRAND"] = "brand";
})(ButtonColor = exports.ButtonColor || (exports.ButtonColor = {}));
exports.OLD_BUTTON_SIZES = {
    sm: sizes_1.SIZES.SMALL,
    md: sizes_1.SIZES.MEDIUM,
    lg: sizes_1.SIZES.LARGE
};
// Support old sizes (backward compatible)
const getActualSize = (size) => {
    if (size && size in exports.OLD_BUTTON_SIZES) {
        //  Element implicitly has an 'any' type because expression of type 'Size' can't be used to index type
        //  '{ readonly sm: "small"; readonly md: "medium"; readonly lg: "large"; }'.
        //   Property 'xxs' does not exist on type '{ readonly sm: "small"; readonly md: "medium"; readonly lg: "large"; }'.
        // @ts-expect-error
        return exports.OLD_BUTTON_SIZES[size];
    }
    return size;
};
exports.getActualSize = getActualSize;
exports.BUTTON_KIND_CLASS_NAMES = {
    PRIMARY: ""
};
exports.BUTTON_ICON_SIZE = 20;
var ButtonInputType;
(function (ButtonInputType) {
    ButtonInputType["BUTTON"] = "button";
    ButtonInputType["SUBMIT"] = "submit";
    ButtonInputType["RESET"] = "reset";
})(ButtonInputType = exports.ButtonInputType || (exports.ButtonInputType = {}));
