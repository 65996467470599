"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.backwardCompatibilityForProperties = void 0;
// eslint-disable-next-line default-param-last
function backwardCompatibilityForProperties(valuesArrayByMostUpdateNaming = [], defaultValue) {
    const value = valuesArrayByMostUpdateNaming.find(currentValue => currentValue !== undefined);
    if (value === undefined) {
        return defaultValue;
    }
    return value;
}
exports.backwardCompatibilityForProperties = backwardCompatibilityForProperties;
