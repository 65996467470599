"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useDisableScroll = (scrollableQuerySelector) => {
    const _disableScroll = (0, react_1.useCallback)((e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }, []);
    const disableScroll = (0, react_1.useCallback)(() => {
        if ((scrollableQuerySelector === null || scrollableQuerySelector === void 0 ? void 0 : scrollableQuerySelector.length) > 0) {
            document.querySelectorAll(scrollableQuerySelector).forEach((item) => {
                item.addEventListener("wheel", _disableScroll);
            });
        }
    }, [_disableScroll, scrollableQuerySelector]);
    const enableScroll = (0, react_1.useCallback)(() => {
        if ((scrollableQuerySelector === null || scrollableQuerySelector === void 0 ? void 0 : scrollableQuerySelector.length) > 0) {
            document.querySelectorAll(scrollableQuerySelector).forEach((item) => {
                item.removeEventListener("wheel", _disableScroll);
            });
        }
    }, [_disableScroll, scrollableQuerySelector]);
    (0, react_1.useEffect)(() => {
        return enableScroll;
    }, [enableScroll]);
    return {
        disableScroll,
        enableScroll
    };
};
exports.default = useDisableScroll;
