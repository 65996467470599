"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavigationCommand = exports.ComponentDefaultTestId = void 0;
var ComponentDefaultTestId;
(function (ComponentDefaultTestId) {
    // Don't remove next line
    // plop_marker:default-data-testid-declarations
    ComponentDefaultTestId["COMBOBOX"] = "combobox";
    ComponentDefaultTestId["DROPDOWN"] = "dropdown";
    ComponentDefaultTestId["BUTTON"] = "button";
    ComponentDefaultTestId["CLICKABLE"] = "clickable";
    ComponentDefaultTestId["VIRTUALIZED_LIST"] = "virtualized-list";
    ComponentDefaultTestId["VIRTUALIZED_GRID"] = "virtualized-grid";
    ComponentDefaultTestId["TEXT_FIELD"] = "text-field";
    ComponentDefaultTestId["TEXT_FIELD_SECONDARY_BUTTON"] = "text-field-secondary-button";
    ComponentDefaultTestId["SEARCH"] = "search";
    ComponentDefaultTestId["CLEAN_SEARCH_BUTTON"] = "clean-search-button";
    ComponentDefaultTestId["COLOR_PICKER_ITEM"] = "color-picker-item";
    ComponentDefaultTestId["ICON_BUTTON"] = "icon-button";
    ComponentDefaultTestId["CHIP"] = "chip";
    ComponentDefaultTestId["TAB"] = "tab";
    ComponentDefaultTestId["TAB_PANEL"] = "tab-panel";
    ComponentDefaultTestId["ALERT_BANNER_BUTTON"] = "alert-banner-button";
    ComponentDefaultTestId["ALERT_BANNER_LINK"] = "alert-banner-link";
    ComponentDefaultTestId["ALERT_BANNER_TEXT"] = "alert-banner-text";
    ComponentDefaultTestId["ATTENTION_BOX"] = "attention-box";
    ComponentDefaultTestId["AVATAR"] = "avatar";
    ComponentDefaultTestId["AVATAR_BADGE"] = "avatar-badge";
    ComponentDefaultTestId["AVATAR_CONTENT"] = "avatar-content";
    ComponentDefaultTestId["BREADCRUMB_ITEM"] = "breadcrumb-item";
    ComponentDefaultTestId["BREADCRUMBS_BAR"] = "breadcrumbs-bar";
    ComponentDefaultTestId["COLOR_PICKER_ITEM_COMPONENT"] = "color-picker-item-component";
    ComponentDefaultTestId["COMBOBOX_CATEGORY"] = "combobox-category";
    ComponentDefaultTestId["COMBOBOX_OPTION"] = "combobox-option";
    ComponentDefaultTestId["COUNTER"] = "counter";
    ComponentDefaultTestId["MENU"] = "menu";
    ComponentDefaultTestId["OPTION"] = "option";
    ComponentDefaultTestId["EDITABLE_HEADING"] = "editable-heading";
    ComponentDefaultTestId["HEADING"] = "heading";
    ComponentDefaultTestId["LABEL"] = "label";
    ComponentDefaultTestId["LINK"] = "link";
    ComponentDefaultTestId["MENU_DIVIDER"] = "menu-divider";
    ComponentDefaultTestId["MENU_ITEM"] = "menu-item";
    ComponentDefaultTestId["MENU_ITEM_BUTTON"] = "menu-item-button";
    ComponentDefaultTestId["MENU_TITLE"] = "menu-title";
    ComponentDefaultTestId["MENU_BUTTON"] = "menu-button";
    ComponentDefaultTestId["STEP_INDICATOR"] = "step-indicator";
    ComponentDefaultTestId["SKELETON"] = "skeleton";
    ComponentDefaultTestId["SPLIT_BUTTON"] = "split-button";
    ComponentDefaultTestId["TEXT_WITH_HIGHLIGHT"] = "text-with-highlight";
    ComponentDefaultTestId["TOAST"] = "toast";
    ComponentDefaultTestId["TOAST_LINK"] = "toast-link";
    ComponentDefaultTestId["TOGGLE"] = "toggle";
    ComponentDefaultTestId["DIVIDER"] = "divider";
    ComponentDefaultTestId["DATEPICKER"] = "date-picker";
    ComponentDefaultTestId["DATEPICKER_HEADER"] = "date-picker-header";
    ComponentDefaultTestId["DATEPICKER_YEAR_SELECTION"] = "date-picker-year-selection";
    ComponentDefaultTestId["LOADER"] = "loader";
    ComponentDefaultTestId["MODAL_BUTTONS"] = "modal-buttons";
})(ComponentDefaultTestId = exports.ComponentDefaultTestId || (exports.ComponentDefaultTestId = {}));
var NavigationCommand;
(function (NavigationCommand) {
    NavigationCommand["RIGHT_ARROW"] = "{arrowright}";
    NavigationCommand["LEFT_ARROW"] = "{arrowleft}";
    NavigationCommand["UP_ARROW"] = "{arrowup}";
    NavigationCommand["DOWN_ARROW"] = "{arrowdown}";
    NavigationCommand["TAB"] = "#TAB#";
    NavigationCommand["ENTER"] = "{enter}";
    NavigationCommand["PAGE_UP"] = "{pageup}";
    NavigationCommand["PAGE_DOWN"] = "{pagedown}";
})(NavigationCommand = exports.NavigationCommand || (exports.NavigationCommand = {}));
