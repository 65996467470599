"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useKeyboardButtonPressedFunc = void 0;
const react_1 = require("react");
const constants_1 = require("../constants");
function useKeyboardButtonPressedFunc(onClick) {
    return (0, react_1.useCallback)((e) => {
        if (e.key === constants_1.keyCodes.SPACE || e.key === constants_1.keyCodes.ENTER) {
            onClick(e);
        }
    }, [onClick]);
}
exports.useKeyboardButtonPressedFunc = useKeyboardButtonPressedFunc;
