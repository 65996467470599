"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlexDirection = exports.FlexGap = exports.FlexJustify = exports.FlexAlign = void 0;
var FlexAlign;
(function (FlexAlign) {
    FlexAlign["START"] = "Start";
    FlexAlign["CENTER"] = "Center";
    FlexAlign["END"] = "End";
    FlexAlign["STRETCH"] = "Stretch";
})(FlexAlign = exports.FlexAlign || (exports.FlexAlign = {}));
var FlexJustify;
(function (FlexJustify) {
    FlexJustify["START"] = "Start";
    FlexJustify["CENTER"] = "Center";
    FlexJustify["END"] = "End";
    FlexJustify["STRETCH"] = "Stretch";
    FlexJustify["SPACE_AROUND"] = "SpaceAround";
    FlexJustify["SPACE_BETWEEN"] = "SpaceBetween";
})(FlexJustify = exports.FlexJustify || (exports.FlexJustify = {}));
var FlexGap;
(function (FlexGap) {
    FlexGap[FlexGap["XS"] = 4] = "XS";
    FlexGap[FlexGap["SMALL"] = 8] = "SMALL";
    FlexGap[FlexGap["MEDIUM"] = 16] = "MEDIUM";
    FlexGap[FlexGap["LARGE"] = 24] = "LARGE";
    FlexGap[FlexGap["NONE"] = 0] = "NONE";
})(FlexGap = exports.FlexGap || (exports.FlexGap = {}));
var FlexDirection;
(function (FlexDirection) {
    FlexDirection["ROW"] = "Row";
    FlexDirection["COLUMN"] = "Column";
})(FlexDirection = exports.FlexDirection || (exports.FlexDirection = {}));
