"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClickableIconScreenReaderAccessProps = exports.getClickableScreenReaderAccessProps = exports.getIconScreenReaderAccessProps = void 0;
const lodash_es_1 = require("lodash-es");
function getIconScreenReaderAccessProps({ isClickable, isDecorationOnly, isKeyboardAccessible, label }) {
    const overrideIsDecorationOnly = (0, lodash_es_1.isNil)(isDecorationOnly) ? !isClickable : isDecorationOnly;
    if (isClickable) {
        return getClickableIconScreenReaderAccessProps({
            label,
            isDecorationOnly: overrideIsDecorationOnly,
            isKeyboardAccessible
        });
    }
    return {
        role: overrideIsDecorationOnly ? undefined : "img",
        "aria-hidden": overrideIsDecorationOnly,
        tabIndex: undefined,
        "aria-label": isDecorationOnly ? undefined : label
    };
}
exports.getIconScreenReaderAccessProps = getIconScreenReaderAccessProps;
function getClickableScreenReaderAccessProps({ isKeyboardAccessible = true, isDecorationOnly = false }) {
    return {
        role: "button",
        tabIndex: isKeyboardAccessible ? 0 : -1,
        "aria-hidden": isDecorationOnly
    };
}
exports.getClickableScreenReaderAccessProps = getClickableScreenReaderAccessProps;
function getClickableIconScreenReaderAccessProps({ label, isDecorationOnly = false, isKeyboardAccessible = true }) {
    return Object.assign(Object.assign({}, getClickableScreenReaderAccessProps({ isDecorationOnly, isKeyboardAccessible })), { "aria-label": label });
}
exports.getClickableIconScreenReaderAccessProps = getClickableIconScreenReaderAccessProps;
